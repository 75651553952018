/*
 * @LastEditTime: 2022-08-10 14:47:02
 * @Description: 以弹窗的形式展示视图配置
 * @FilePath: /dwh_front/src/global/actions/ShowViewForm.js
 * @Date: 2022-04-13 16:31:33
 * @Author: lisushuang
 * @LastEditors: luocheng
 */
/* eslint-disable */
import baseAction from "./baseAction"
import MarkDownShow from "../../views/objects/components/markdownShow"
import objects from "../../api/objects"

export default class ShowViewDocument extends baseAction {


    constructor() {
        super()
    }

    /**
     * @description: 核心处理方法
     */
    handler() {
        this.getLoading("查询中")
        // let _this = this
        let view_uuid = null
        if (this.props.view_uuid) {
            view_uuid = this.props.view_uuid
        } else if (this.props.uuid) {
            view_uuid = this.props.uuid
        } else {
            view_uuid = this.props
        }

        if (!view_uuid) {
            this.showError('展示视图详情：错误的参数！')
            return
        }

        objects.getViewDocument(view_uuid).then(res => {
            this.loading.close()
            if (res.data.code == 200) {
                let viewData = res.data.data
                let content = () => {
                    // let h = this.dialogInstance.$createElement
                    return (
                        <MarkDownShow text={viewData}/>
                    )
                }
                this.showPopDialog(
                    content,
                    '视图使用文档'
                )
                this.loading.close()
            } else {
                this.loading.close()
                this.showError(res.data.msg)
            }

        }).catch(() => this.loading.close())


    }
}
