<template>
  <div class="App">
    <markdown-preview v-model="text"/>
  </div>
</template>

<script>
import MarkdownPreview from 'markdown-preview-vue'

export default {
  name: "markdownShow",
  components: {
    MarkdownPreview
  },
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {
  },
};
</script>
